import { FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import { faChevronDown, faSearch, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import { fullTextLeagueSearch, useBettableSports } from '../../../lib/odds'
import { sortArray } from '../../../util/array'
import { useDebounce } from '../../../util/hooks'
import SportLeagueBrowserSportsItem from './SportItem'
import NoSearchResult from '../../../public/no-result.svg'
import Trans from 'next-translate/Trans'
import { useSportsContext } from '../../../context/sports.context'
import { overrideWithTranslatedName } from '../../../util/translate'
import useCurrentLanguage from '../../../hooks/useCurrentLanguage'
import { useUserFavoriteLeagues } from '../../../lib/user'
import { isNullOrEmpty } from '../../../util/string'
import { SelectOption } from '../../../util/select-option'
import { getDatesForDuration } from '../../../util/date'
import { useRouter } from 'next/router'
import { LeagueSearchQueryType } from '@arland-bmnext/api-data'

const SearchBar = ({ redirectToSearchPage }) => {
  const { slbState, updateSlbState } = useSportsContext()
  const router = useRouter()
  const searchTerm = slbState?.searchTerm
  const { t } = useTranslation('common')

  const onSearchTermChange = (ev) => {
    updateSlbState('searchTerm', ev.target.value)
  }

  const resetSearchTerm = () => {
    updateSlbState('searchTerm', '')
  }

  const goToSearchPage = () => {
    router.push('/sports/search')
  }

  return (
    <div
      className="slb-search-bar flex items-center h-[52px] bg-card text-cardContrast rounded-md hover:bg-cardHover shadow-lg border-[1px] border-neutral"
      onClick={redirectToSearchPage ? goToSearchPage : null}
    >
      <div className="slb-search-bar-icon flex justify-center w-[52px]">
        <FontAwesomeIcon icon={faSearch} />
      </div>

      <div className="slb-search-bar-input-wrapper flex flex-grow h-full items-center">
        <input
          value={redirectToSearchPage ? undefined : searchTerm}
          onChange={onSearchTermChange}
          placeholder={t('SLB.searchPlaceholder')}
          className="slb-search-bar-input w-full h-full bg-transparent focus:outline-none text-sm"
          onClick={redirectToSearchPage ? goToSearchPage : null}
          readOnly={redirectToSearchPage}
        />

        {searchTerm.length > 0 && !redirectToSearchPage && (
          <FontAwesomeIcon
            className="slb-search-bar-clear px-4 cursor-pointer"
            icon={faTimesCircle}
            onClick={resetSearchTerm}
          />
        )}
      </div>
    </div>
  )
}

type SportLeagueBrowserProps = {
  mode: 'add-favorites' | 'slb'
  redirectToSearchPage?: boolean
}
const SportLeagueBrowser = ({ mode, redirectToSearchPage = false }: SportLeagueBrowserProps) => {
  const { t } = useTranslation('common')
  const language = useCurrentLanguage()
  const { slbState, updateSlbState } = useSportsContext()
  const { topSports, sortedSports, searchResult, searchTerm } = slbState
  const [startDate, endDate] = getDatesForDuration(mode === 'slb' ? slbState.duration : 'all')
  const { bettableSports, isValidating: sportsValidating } = useBettableSports(startDate, endDate, language?.id)
  const { userFavoriteLeagues } = useUserFavoriteLeagues(language?.id)
  const [categoriesContainingFavs, setCategoriesContainingFavs] = useState(null)
  const debouncedSearchTerm = useDebounce(slbState?.searchTerm, 300)

  const durations: SelectOption[] = [
    { name: t('Common.Durations.today'), value: 'today' },
    { name: t('Common.Durations.7days'), value: '7days' },
    { name: t('Common.Durations.all'), value: 'all' },
  ]

  useEffect(() => {
    const _sports = overrideWithTranslatedName(bettableSports, language?.id)?.filter(
      (sport) => sport.name != null && sport.name !== '',
    )
    const _topSports = sortArray(_sports, 'sortKey', 'desc').slice(0, 5)
    const _sortedSports = sortArray(_sports, 'name', 'asc').filter(
      (sport) => _topSports?.find((topSport) => sport.id == topSport.id) == null,
    )

    updateSlbState('sports', _sports)
    updateSlbState('topSports', _topSports)
    updateSlbState('sortedSports', _sortedSports)
  }, [bettableSports])

  useEffect(() => {
    searchLeaguesFulltext(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  useEffect(() => {
    calculateSportAndCategoryFavCounts()
  }, [userFavoriteLeagues])

  const searchLeaguesFulltext = async (fulltext: string) => {
    if (fulltext == '' || fulltext == null) updateSlbState('searchResult', null)

    if (fulltext?.length > 2) {
      const res = await fullTextLeagueSearch(language?.id, fulltext, LeagueSearchQueryType.OnlyLeagues, 0, 100)
      updateSlbState('searchResult', res)
    }
  }

  const calculateSportAndCategoryFavCounts = () => {
    if (isNullOrEmpty(userFavoriteLeagues)) return

    const sportFavs = {}
    const categoryFavs = {}

    userFavoriteLeagues?.forEach((fav: FavoriteLeagueDTO) => {
      if (sportFavs[fav.sportId] == null) sportFavs[fav.sportId] = 1
      else sportFavs[fav.sportId]++

      if (categoryFavs[fav.gameCategoryId] == null) categoryFavs[fav.gameCategoryId] = 1
      else categoryFavs[fav.gameCategoryId]++
    })

    setCategoriesContainingFavs(categoryFavs)
  }

  const onDurationChange = (ev) => {
    changeDuration(ev.target.value)
  }

  const changeDuration = (duration) => {
    updateSlbState('duration', duration)
    updateSlbState('activeSportIds', {})
    updateSlbState('categories', {})
    updateSlbState('topCategories', {})
    updateSlbState('sortedCategories', {})
    updateSlbState('activeCategoryIds', {})
    updateSlbState('leagues', {})
  }

  return (
    <div className="slb flex flex-col space-y-4">
      <SearchBar redirectToSearchPage={redirectToSearchPage} />

      <div className="slb-sports flex flex-col space-y-2">
        {(searchResult == null || redirectToSearchPage) && (
          <>
            <div className="flex justify-between items-center">
              {topSports?.length > 0 && (
                <span className="slb-sports-heading text-xs font-semibold uppercase opacity-70">
                  {t('SLB.listHeaderPopular')}
                </span>
              )}

              {mode === 'slb' && (
                <div className="slb-duration flex items-center relative pr-4">
                  <select
                    className="slb-duration-select flex bg-transparent text-xs font-bold text-right overflow-hidden overflow-ellipsis whitespace-nowrap rounded-sm focus:outline-none appearance-none cursor-pointer"
                    onChange={onDurationChange}
                    value={slbState.duration}
                  >
                    {durations?.map((duration) => (
                      <option value={duration.value} key={duration.value} className="text-black">
                        {duration.name}
                      </option>
                    ))}
                  </select>

                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="slb-duration-select-indicator text-xs absolute right-0 opacity-60 pointer-events-none"
                  />
                </div>
              )}
            </div>

            {topSports?.map((sport) => (
              <SportLeagueBrowserSportsItem
                languageId={language?.id}
                sportId={sport.id}
                sportName={sport.name}
                sportShortSign={sport.shortSign}
                favoriteLeagues={userFavoriteLeagues}
                categoriesContainingFavs={categoriesContainingFavs}
                startDate={startDate}
                endDate={endDate}
                mode={mode}
                key={sport.id}
              />
            ))}

            {sortedSports?.length > 0 && (
              <span className="slb-sports-heading flex py-2 text-xs font-semibold uppercase opacity-70">
                {t('SLB.listHeaderAlphabetic')}
              </span>
            )}

            {sortedSports?.map((sport) => (
              <SportLeagueBrowserSportsItem
                languageId={language?.id}
                sportId={sport.id}
                sportName={sport.name}
                sportShortSign={sport.shortSign}
                favoriteLeagues={userFavoriteLeagues}
                categoriesContainingFavs={categoriesContainingFavs}
                startDate={startDate}
                endDate={endDate}
                mode={mode}
                key={sport.id}
              />
            ))}
          </>
        )}

        {searchResult &&
          !redirectToSearchPage &&
          searchResult.sports?.map((sport) => (
            <SportLeagueBrowserSportsItem
              languageId={language?.id}
              sportId={sport.sportId}
              sportName={sport.sportName}
              sportShortSign={sport.sportShortSign}
              searchResultSportGameCategories={sport.gameCategories}
              leagueSearchTerm={searchTerm}
              favoriteLeagues={userFavoriteLeagues}
              categoriesContainingFavs={categoriesContainingFavs}
              startDate={startDate}
              endDate={endDate}
              mode={mode}
              key={sport.sportId}
            />
          ))}

        {mode === 'slb' && slbState.duration === 'today' && !sportsValidating && (
          <span className="slb-duration-info flex flex-col text-xs text-center px-4 pt-4 pb-8">
            <Trans
              i18nKey="common:SLB.durationFilterTodayInfo"
              components={[
                <span />,
                <span
                  className="text-primary font-bold underline cursor-pointer"
                  onClick={() => changeDuration('all')}
                />,
              ]}
            />
          </span>
        )}

        {mode === 'slb' && slbState.duration === '7days' && !sportsValidating && (
          <span className="slb-duration-info flex flex-col text-xs text-center px-4 pt-4 pb-8">
            <Trans
              i18nKey="common:SLB.durationFilterWeekInfo"
              components={[
                <span />,
                <span
                  className="text-primary font-bold underline cursor-pointer"
                  onClick={() => changeDuration('all')}
                />,
              ]}
            />
          </span>
        )}
      </div>

      {searchTerm != null && searchTerm != '' && searchResult?.sports?.length === 0 && !redirectToSearchPage && (
        <div className="slb-search-result-empty flex flex-col space-y-4 items-center p-8">
          <div className="slb-search-result-empty-svg flex text-primary">
            <NoSearchResult />
          </div>

          <span className="slb-search-result-empty-text text-center">
            <Trans
              i18nKey="common:SLB.noResult"
              values={{ searchTerm }}
              components={[<span className="text-primary" />]}
            />
          </span>
        </div>
      )}
    </div>
  )
}

export default SportLeagueBrowser
