import {
  faBasketball,
  faBoxingGlove,
  faHockeyMask,
  faHockeySticks,
  faShuttlecock,
  faTennisBall,
  faVolleyball,
  faRugbyBall as faRugbyBallSolid,
  faTableTennisPaddleBall,
  faCricketBatBall,
  faBaseball,
  faPersonSkiingNordic,
  faBullseyeArrow,
  faGolfFlagHole,
  faSparkles,
  faFootball,
  faPersonSkiing,
  faGamepadModern,
  faFieldHockeyStickBall as faFieldHockeyStickBallRegular,
  faCurlingStone,
  faUniformMartialArts,
  faBowlingBall,
  faBaseballBatBall,
  faClover,
  faUmbrellaBeach,
  faSailboat,
  faPersonBikingMountain,
  faRugbyBall,
  faCarBump,
  faCarBolt,
  faPersonBiking,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faFieldHockeyStickBall,
  faPersonRunning,
  faPersonSkiJumping,
  faRacquet,
  faVolleyball as faVolleyballSolid,
} from '@fortawesome/pro-solid-svg-icons'
import { faFutbol as faFutbolLight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultSportPlaceholder from '../../public/sports/custom-placeholder-regular.svg'
import HandballIcon from '../../public/sports/custom-handball-regular.svg'
import WaterpoloIcon from '../../public/sports/custom_waterball-regular.svg'
import SnookerIcon from '../../public/sports/custom-pool-regular.svg'
import LoLIcon from '../../public/sports/custom-leagueOfLegends-regular.svg'
import DotaIcon from '../../public/sports/custom-dota2-regular.svg'
import CsGoIcon from '../../public/sports/custom-CounterStrike-regular.svg'
import OverwatchLogo from '../../public/sports/custom-Overwatch-regular.svg'
import SoccerIcon from '../../public/sports/custom_soccer-regular.svg'

const SportIcon = ({ sportShortSign, color = 'text-inherit', width = '20px', height = '20px' }) => {
  const getSportsIcon = () => {
    switch (sportShortSign) {
      case 'ho':
        return faHockeyMask
      case 'mo':
        return faCarBump
      case 'bx':
        return faBoxingGlove
      case 'vo':
        return faVolleyball
      case 'te':
        return faTennisBall
      case 'ba':
        return faBasketball
      case 'ar':
        return faRugbyBall
      case 'bd':
        return faHockeySticks
      case 'bm':
        return faShuttlecock
      case 'rg':
        return faRugbyBallSolid
      case 'tt':
        return faTableTennisPaddleBall
      case 'cr':
        return faCricketBatBall
      case 'cyc':
        return faPersonBiking
      case 'ml':
        return faBaseball
      case 'fs':
        return faFutbolLight
      case 'bln':
        return faPersonSkiingNordic
      case 'bvb':
        return faVolleyballSolid
      case 'fb':
        return faFieldHockeyStickBall
      case 'da':
        return faBullseyeArrow
      case 'go':
        return faGolfFlagHole
      case '_specials':
        return faSparkles
      case 'af':
        return faFootball
      case 'si':
        return faPersonSkiing
      case 'sqsh':
        return faRacquet
      case 'fifa':
        return faGamepadModern
      case 'fh':
        return faFieldHockeyStickBallRegular
      case 'sj':
        return faPersonSkiJumping
      case 'cu':
        return faCurlingStone
      case 'mma':
        return faUniformMartialArts
      case 'fe':
        return faCarBolt
      case 'bo':
        return faBowlingBall
      case 'pe':
        return faBaseballBatBall
      case 'gfb':
        return faClover
      case 'ghu':
        return faClover
      case 'bs':
        return faUmbrellaBeach
      case 'sail':
        return faSailboat
      case 'cc':
        return faPersonBikingMountain
      case 'at':
        return faPersonRunning
      default:
        return null
    }
  }

  const getCustomIcon = () => {
    switch (sportShortSign) {
      case 'fu':
        return SoccerIcon
      case 'ha':
        return HandballIcon
      case 'wp':
        return WaterpoloIcon
      case 'sn':
        return SnookerIcon
      case 'lol':
        return LoLIcon
      case 'dota2':
        return DotaIcon
      case 'csgo':
        return CsGoIcon
      case 'ow':
        return OverwatchLogo
      default:
        return DefaultSportPlaceholder
    }
  }

  const sportIcon = getSportsIcon()
  const CustomIcon = getCustomIcon()

  return (
    <div className={`sport-icon flex justify-center items-center ${color}`} style={{ height, width }}>
      {sportIcon != null ? (
        <FontAwesomeIcon className="sport-icon-svg" icon={sportIcon} />
      ) : (
        <CustomIcon className="sport-icon-svg h-full w-full" />
      )}
    </div>
  )
}

export default SportIcon
