import { LeagueSearchResultLeague } from '@arland-bmnext/api-data'
import { FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useSportsContext } from '../../../context/sports.context'
import { useCommonContext } from '../../../context/common.context'
import { getBettableLeagues } from '../../../lib/odds'
import Badge, { IconBadge } from '../../core/Badge'
import CountryIcon from '../../core/CountryIcon'
import SportLeagueBrowserLeagueItem from './LeagueItem'

type SportLeagueBrowserCategoryItemProps = {
  categoryId: number
  categoryName: string
  categoryShortSign: string
  categoryCountryId: number
  searchResultCategoryLeagues?: LeagueSearchResultLeague[]
  favoriteLeagues: FavoriteLeagueDTO[]
  sportId: number
  languageId: number
  leagueSearchTerm?: string
  startDate?: string
  categoriesContainingFavs: any
  endDate?: string
  gameCount?: number
  mode: 'add-favorites' | 'slb'
}
const SportLeagueBrowserCategoryItem = ({
  categoryId,
  categoryName,
  categoryShortSign,
  categoryCountryId,
  searchResultCategoryLeagues = null,
  leagueSearchTerm,
  sportId,
  favoriteLeagues,
  languageId,
  categoriesContainingFavs,
  startDate,
  endDate,
  mode,
  gameCount,
}: SportLeagueBrowserCategoryItemProps) => {
  const identifier = `${sportId}-${categoryId}`
  const { slbState, updateSlbState } = useSportsContext()
  const isOpen = slbState?.activeCategoryIds[identifier] === true || searchResultCategoryLeagues != null
  const leagues = slbState?.leagues[identifier] ?? []

  const commonContext = useCommonContext()
  const country = commonContext.countries?.find(
    (c) => c.id === categoryCountryId || c['isoAlpha3'] === categoryShortSign,
  )
  const countryCode = country ? country.shortSign : categoryShortSign != '' ? categoryShortSign : null

  const onCategoryItemClick = async () => {
    if (searchResultCategoryLeagues == null && (leagues == null || leagues.length == 0)) await loadLeagues()
    updateSlbState('activeCategoryIds', { ...slbState.activeCategoryIds, [identifier]: !isOpen })
  }

  const loadLeagues = async () => {
    const loadedLeagues = await getBettableLeagues(sportId, categoryId, startDate, endDate, languageId)
    const sortedLeagues = loadedLeagues?.sort((a, b) => b.sortKey - a.sortKey || a.name.localeCompare(b.name))
    updateSlbState('leagues', { ...slbState.leagues, [identifier]: sortedLeagues })
  }

  return (
    <div className="slb-category flex flex-col bg-slbCategoryItem text-slbCategoryItemContrast rounded-md border-[1px] border-neutral px-2">
      <div
        className={`slb-category-item flex justify-between items-center py-2 pr-2 cursor-pointer`}
        onClick={onCategoryItemClick}
      >
        <div className="slb-category-item-inner flex grow items-center space-x-2 hover:underline">
          <div className="flex items-center space-x-2 text-xs">
            <div className="slb-category-item-icon bg-gray-500 bg-opacity-10 rounded-full p-1 w-7 h-7">
              <CountryIcon countryCode={countryCode} />
            </div>
            <span className={`slb-category-item-name ${isOpen ? 'font-bold' : ''}`}>{categoryName}</span>
          </div>
        </div>

        <div className="slb-category-item-additional flex space-x-4 items-center">
          {mode === 'slb' && (
            <div className="slb-category-item-additional-gamecount flex space-x-2 items-center">
              {gameCount > 0 && (
                <Badge
                  value={gameCount}
                  className="!text-xxs font-semibold"
                  backgroundColor="bg-gray-500 bg-opacity-10"
                  textColor="text-slbCategoryItemContrast"
                />
              )}
            </div>
          )}

          {mode === 'add-favorites' && categoriesContainingFavs && categoriesContainingFavs[categoryId] && (
            <IconBadge icon={faStar} value={categoriesContainingFavs[categoryId]} />
          )}

          {searchResultCategoryLeagues == null && (
            <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} className="text-xs aspect-square" />
          )}
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <>
            {searchResultCategoryLeagues == null && leagues?.length > 0 && (
              <div className="slb-category-leagues flex flex-col space-y-4 pb-4 pt-2">
                {leagues?.map((league) => (
                  <SportLeagueBrowserLeagueItem
                    leagueId={league.id}
                    leagueName={league.name}
                    leagueSearchTerm={leagueSearchTerm}
                    categoryId={categoryId}
                    sportId={sportId}
                    favoriteLeagues={favoriteLeagues}
                    mode={mode}
                    gameCount={league.gameCount}
                    key={league.id}
                  />
                ))}
              </div>
            )}

            {searchResultCategoryLeagues != null && searchResultCategoryLeagues.length > 0 && (
              <div className="slb-category-leagues flex flex-col space-y-4 py-4">
                {searchResultCategoryLeagues?.map((league) => (
                  <SportLeagueBrowserLeagueItem
                    leagueId={league.leagueId}
                    leagueName={league.leagueName}
                    leagueSearchTerm={leagueSearchTerm}
                    categoryId={categoryId}
                    sportId={sportId}
                    favoriteLeagues={favoriteLeagues}
                    mode={mode}
                    key={league.leagueId}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SportLeagueBrowserCategoryItem
