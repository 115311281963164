import React from 'react'
import ReactCountryFlag from 'react-country-flag'

const customFlags = ['ENG', 'SCO', 'WAL', 'NIR']

const CountryIcon = ({ countryCode, rounded = true, className = '' }) => {
  return (
    <div className="country-icon flex justify-center items-center flex-shrink-0 h-full w-full aspect-square">
      {customFlags.includes(countryCode) ? (
        <>
          {countryCode === 'ENG' && (
            <img
              src="/flags/england.svg"
              className={'!flex object-cover !h-full !w-full ' + (rounded ? 'rounded-full ' : '') + className}
              alt={'Flag_' + countryCode}
            />
          )}
          {countryCode === 'SCO' && (
            <img
              src="/flags/scotland.svg"
              className={'!flex object-cover !h-full !w-full ' + (rounded ? 'rounded-full ' : '') + className}
              alt={'Flag_' + countryCode}
            />
          )}
          {countryCode === 'WAL' && (
            <img
              src="/flags/wales.svg"
              className={'!flex object-cover !h-full !w-full ' + (rounded ? 'rounded-full ' : '') + className}
              alt={'Flag_' + countryCode}
            />
          )}
          {countryCode === 'NIR' && (
            <img
              src="/flags/northern_ireland.svg"
              className={'!flex object-cover !h-full !w-full ' + (rounded ? 'rounded-full ' : '') + className}
              alt={'Flag_' + countryCode}
            />
          )}
        </>
      ) : countryCode != null && countryCode != '' ? (
        <ReactCountryFlag
          svg
          className={'!flex object-cover !h-full !w-full ' + (rounded ? 'rounded-full ' : '') + className}
          countryCode={countryCode}
          alt={'Flag_' + countryCode}
        />
      ) : (
        <img
          src="/flags/world.svg"
          className={'!flex object-cover !w-full !h-full ' + (rounded ? 'rounded-full ' : '') + className}
          alt={'Flag_International'}
        />
      )}
    </div>
  )
}

export default CountryIcon
