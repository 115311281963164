import {
  GameCategory,
  League,
  LeagueSearchResultGroupedBySportAndGameCategory,
  LeagueSearchResultSport,
  Sport,
} from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useContext, useEffect, useState } from 'react'
import { Tab } from '../components/core/Tabs'

type SLBState = {
  duration?: 'today' | '7days' | 'all'
  sports?: Sport[]
  topSports?: Sport[]
  sortedSports?: Sport[]
  activeSportIds?: { [sportId: number]: boolean }
  categories?: { [sportId: number]: GameCategory[] }
  topCategories?: { [sportId: number]: GameCategory[] }
  sortedCategories?: { [sportId: number]: GameCategory[] }
  activeCategoryIds?: { [identifier: number]: boolean }
  leagues?: { [identifier: number]: League[] }
  searchTerm?: string
  searchResult?: LeagueSearchResultGroupedBySportAndGameCategory
}

type GameSearchState = {
  searchTerm?: string
  searchResult?: LeagueSearchResultGroupedBySportAndGameCategory
  searchResultSports?: Tab[]
  searchResultActiveSportId?: number
  searchResultActiveSport?: LeagueSearchResultSport
}

type SportsContextProps = {
  slbState?: SLBState
  updateSlbState?: (...args) => void
  resetSlbState?: () => void
  resetSlbSearch?: () => void
  gameSearchState?: GameSearchState
  updateGameSearchState?: (...args) => void
  resetGameSearchState?: () => void
  topGamesWidgetSportId?: number
  setTopGamesWidgetSportId?: (sportId: number) => void
  sportRadarWidgetEventExternalId: string
  setSportRadarWidgetEventExternalId: (externalId: string) => void
}

const initialSlbState: SLBState = {
  duration: 'all',
  sports: [],
  topSports: [],
  sortedSports: [],
  activeSportIds: {},
  categories: {},
  topCategories: {},
  sortedCategories: {},
  activeCategoryIds: {},
  leagues: {},
  searchTerm: '',
  searchResult: null,
}

const initialGameSearchState: GameSearchState = {
  searchTerm: '',
  searchResult: null,
  searchResultSports: null,
  searchResultActiveSportId: null,
  searchResultActiveSport: null,
}

export const SportsContext = createContext<SportsContextProps | undefined>(undefined)

export const useSportsContext = () => {
  const value = useContext(SportsContext)
  if (process.env.NODE_ENV !== 'production') {
    if (value === undefined) {
      throw new Error('useSportsContext must be wrapped in a <SportsProvider />')
    }
  }
  return value
}

const SportsProvider = ({ children }) => {
  const { lang } = useTranslation()

  const [slbState, setSlbState] = useState<SLBState>(initialSlbState)
  const [gameSearchState, setGameSearchState] = useState<GameSearchState>(initialGameSearchState)
  const [topGamesWidgetSportId, setTopGamesWidgetSportId] = useState<number>(null)
  const [sportRadarWidgetEventExternalId, setSportRadarWidgetEventExternalId] = useState<string>(null)

  useEffect(() => {
    resetGameSearchState()
    updateSlbState('activeSportIds', {})
    updateSlbState('categories', {})
    updateSlbState('topCategories', {})
    updateSlbState('sortedCategories', {})
    updateSlbState('activeCategoryIds', {})
    updateSlbState('leagues', {})
  }, [lang])

  const updateSlbState = (propName: string, propValue: any) => {
    setSlbState((prev) => ({ ...prev, [propName]: propValue }))
  }

  const resetSlbState = () => {
    setSlbState(initialSlbState)
  }

  const resetSlbSearch = () => {
    setSlbState((prev) => ({
      ...prev,
      searchTerm: initialSlbState.searchTerm,
      searchResult: initialSlbState.searchResult,
    }))
  }

  const updateGameSearchState = (propName: string, propValue: any) => {
    setGameSearchState((prev) => ({ ...prev, [propName]: propValue }))
  }

  const resetGameSearchState = () => {
    setGameSearchState(initialSlbState)
  }

  const value = {
    slbState,
    updateSlbState,
    resetSlbState,
    resetSlbSearch,
    gameSearchState,
    updateGameSearchState,
    resetGameSearchState,
    topGamesWidgetSportId,
    setTopGamesWidgetSportId,
    sportRadarWidgetEventExternalId,
    setSportRadarWidgetEventExternalId,
  }

  return <SportsContext.Provider value={value}>{children}</SportsContext.Provider>
}

export default SportsProvider
