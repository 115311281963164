import { FavoriteLeagueDTO } from '@arland-bmnext/webapps-api-data'
import { faMinus, faPlus, faStar } from '@fortawesome/pro-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useLiveContext } from '../../../context/live.context'
import { useSportsBettingSettings } from '../../../lib/content'
import {
  addUserFavoriteLeague,
  isAnonymousUser,
  removeUserFavoriteLeague,
  useUser,
  useUserFavoriteLeagues,
} from '../../../lib/user'
import { alertService } from '../../../services/alert.service'
import { buildLeagueUrl } from '../../../util/url'
import Badge from '../../core/Badge'
import { IconButton } from '../../core/Button'
import Tag from '../../core/Tag'
import useTranslation from 'next-translate/useTranslation'
import useCurrentLanguage from '../../../hooks/useCurrentLanguage'

type SportLeagueBrowserLeagueItemProps = {
  leagueId: number
  leagueName: string
  leagueSearchTerm: string
  sportId: number
  categoryId: number
  favoriteLeagues: FavoriteLeagueDTO[]
  gameCount?: number
  mode: 'add-favorites' | 'slb'
}
const SportLeagueBrowserLeagueItem = ({
  leagueId,
  leagueName,
  leagueSearchTerm,
  sportId,
  categoryId,
  favoriteLeagues,
  mode,
  gameCount,
}: SportLeagueBrowserLeagueItemProps) => {
  const { t } = useTranslation('common')
  const liveContext = useLiveContext()
  const router = useRouter()
  const language = useCurrentLanguage()
  const { user } = useUser()
  const { favoriteLeagueLimit } = useSportsBettingSettings()
  const { mutateUserFavoriteLeagues } = useUserFavoriteLeagues(language?.id)
  const [isFavorite, setIsFavorite] = useState(false)

  const liveGameCount = liveContext?.liveGames?.filter((game) => game.leagueId === leagueId)?.length

  useEffect(() => {
    setIsFavorite(favoriteLeagues?.find((x) => x.id === leagueId) != null)
  }, [favoriteLeagues])

  const onAddFavorite = async () => {
    if (isAnonymousUser(user)) {
      return router.push('/account/login')
    }

    addUserFavoriteLeague(leagueId)

    const favs = [
      ...favoriteLeagues,
      { id: leagueId, name: leagueName, sportId: sportId, gameCategoryId: categoryId } as FavoriteLeagueDTO,
    ]

    mutateUserFavoriteLeagues([...favs], { revalidate: false })

    if (favs?.length === favoriteLeagueLimit) {
      alertService.info(t('SLB.maxFavoritesReached', { maxFavoritesCount: favoriteLeagueLimit }))
    }
  }

  const onRemoveFavorite = async () => {
    removeUserFavoriteLeague(leagueId)
    const favs = [...favoriteLeagues.filter((fav) => fav.id != leagueId)]
    mutateUserFavoriteLeagues([...favs], { revalidate: false })
  }

  const onLeagueClick = () => {
    if (mode === 'slb') {
      router.push(buildLeagueUrl(leagueId, leagueName))
    }
  }

  return (
    <div className="slb-league flex flex-col">
      <div className="slb-league-item flex justify-between items-center">
        <div className="slb-league-item-inner flex items-center space-x-2 w-full">
          <div className="flex justify-center items-center shrink-0 w-[28px]">
            {isFavorite ? (
              <FontAwesomeIcon
                icon={faStarSolid}
                className="slb-league-item-fav-selected text-yellow-400 cursor-pointer"
                onClick={onRemoveFavorite}
              />
            ) : (
              <>
                {favoriteLeagues?.length < favoriteLeagueLimit && (
                  <FontAwesomeIcon
                    icon={faStar}
                    className="slb-league-item-fav text-gray-500 text-opacity-30 hover:text-yellow-400 cursor-pointer transition"
                    onClick={onAddFavorite}
                  />
                )}
              </>
            )}
          </div>

          <div
            className="slb-league-item-name hover:underline flex grow text-xs cursor-pointer"
            onClick={onLeagueClick}
          >
            <Highlighter
              highlightClassName="text-primary bg-transparent"
              searchWords={[leagueSearchTerm]}
              autoEscape={true}
              textToHighlight={leagueName}
            />
          </div>

          <div className="slb-league-item-details flex items-center !ml-auto pr-9">
            {mode === 'slb' && (
              <div className="slb-league-item-details-inner flex space-x-2 items-center">
                {liveGameCount > 0 && (
                  <Tag
                    label={'LIVE ' + liveGameCount}
                    backgroundColor="bg-live"
                    textColor="text-liveContrast"
                    className={'slb-league-item-details-livegamecount !rounded-full !px-2'}
                  />
                )}
                {gameCount > 0 && (
                  <Badge
                    value={gameCount}
                    className="slb-league-item-details-gamecount !text-xxs font-semibold"
                    backgroundColor="bg-gray-500 bg-opacity-10"
                    textColor="text-slbCategoryItemContrast"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {mode === 'add-favorites' && (
          <div>
            {isFavorite ? (
              <IconButton
                onClick={onRemoveFavorite}
                icon={faMinus}
                size="xxs"
                rounded
                backgroundColor="bg-red-500"
                borderColor="border-red-500"
                textColor="text-white"
              ></IconButton>
            ) : (
              favoriteLeagues?.length < favoriteLeagueLimit && (
                <IconButton
                  onClick={onAddFavorite}
                  icon={faPlus}
                  size="xxs"
                  rounded
                  backgroundColor="bg-blue-500"
                  borderColor="border-blue-500"
                  textColor="text-white"
                ></IconButton>
              )
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SportLeagueBrowserLeagueItem
